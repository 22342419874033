import React from 'react'
import {
  Container,
  Header
} from './styled'

export default function ConceptTags () {
  return (
    <Container>
      <Header>Concept Tags</Header>
    </Container>
  )
};
