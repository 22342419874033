import styled from 'styled-components'
import { buttonEventsBehavior } from '../../styles/buttons'

export const PageWrapper = styled.div`
  margin: 48px 24px 0 24px;
  display: flex;
  flex-direction: column;

  & * {
    --sb-track-color: transparent;
    --sb-thumb-color: #5b6067;
    --sb-size: 18px;

    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

    &::-webkit-scrollbar {
      width: var(--sb-size);
      height: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--sb-thumb-color);
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #98a4ae;
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`
export const HeaderSection = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`
export const Separator = styled.hr`
  border: 0.5px solid var(--border-secondary);
  margin: ${({ space }) => space} 0;
`
export const SearchModalButton = styled.button`
  display: flex;
  gap: 6px;
  padding: 8px;
  border: 0;
  background: var(--brand-dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  border-radius: 5px;

  ${buttonEventsBehavior}
  &:hover {
    color: var(--brand-regular-hover);
    background: #214b52;
  }

  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
