import styled from 'styled-components'
import { ReactComponent as ExpandIcon } from '../../../assets/icons/arrows-angle-expand.svg'
import mediaqueries from '../../../mediaQueries'

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  margin-left: 10px;
`

export const ExpandLinkedCell = styled(ExpandIcon)`
  margin-right: 12px;
  border: none;

  &.expand-empty-linked-cell {
    margin-right: 0;
  }
`

export const ExpandLinkedCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(270deg, #111113 33.86%, rgba(0, 0, 0, 0) 89.05%);
  cursor: pointer;
  display: none;

  &.active-empty-cell {
    display: flex;
  } 
`

export const Cell = styled.div`
  max-height: 80px;
  overflow: hidden auto;
  :has(.linked-records) {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  &:hover ${ExpandLinkedCellWrapper} {
    display: flex;
  }
  &.empty-cell:hover ${ExpandLinkedCellWrapper} {
    display: none;
  }  
`

export const CellSpacer = styled.div`
  flex: 1;
`

export const CellItem = styled.li`
  display: inline;
  cursor: pointer;
  background: var(--gray-primary);
  border-radius: 5px;
  padding: 8px;
  white-space: nowrap;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
`
export const CellList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
`

export const CellTextContainer = styled.div`
  display: flex;
  width: 100%;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

export const CellText = styled.div`
  display: flex;
`

export const ExpandedRecordsModal = styled.div`
  width: ${mediaqueries.desktop ? '480px' : '300px'};
  position: fixed;
  z-index: 5;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  background: var(--gray-primary);
  & > :last-child {
    margin-left: auto;
  }
`
export const ColumnTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin: 0;
`

export const LinkNewRecord = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--brand-medium);
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  & :hover {
    color: var(--brand-light);
  }
  & :active {
    color: var(--brand-dark);
  }
`

export const CloseButton = styled.span`
  cursor: pointer;
`
export const BodyContainer = styled.div`
  max-height: 360px;
  min-height: 105px;
  background-color: var(--information-card-background-color);
  padding: 24px;
  padding-right: 12px;
  overflow: auto;
  scrollbar-gutter: stable;
`
export const LinkedItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const LinkedItem = styled.li`
  background: var(--gray-primary);
  padding: 8px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  overflow-wrap: anywhere;
  & svg {
    cursor: pointer;
    color: var(--sub-text-secondary);
    min-width: 16px;
  }

  :hover {
    background: #2E3136;
  }
`
export const UnlinkedRecordToastContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    color: var(--sub-text-secondary);
  }
  & > span {
    margin-left: 8px;
    font-weight: 700;
    color: var(--white);
  }
`
export const ModalBody = styled.div`
  & > p {
    color: var(--white);
    margin: 0;
    margin-bottom: 32px;
    font-size: 18px;
  }
  padding: 24px;
`
export const ResizeHandle = styled.div`
  display: none;
  width: 4px;
  height: 100%;
  background-color: ${(props) =>
    props.isResizing ? 'var(--brand-light)' : 'var(--brand-regular)'};
  cursor: col-resize;
`
export const ResizeHandleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  position: absolute;
  top: 0;
  right: -8px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
  &:hover ${ResizeHandle} {
    display: block;
  }
`
export const TableComponent = styled.table`
  ${(props) =>
    props.isResizingColumn && `
    * {
      user-select: none;
      cursor: col-resize;
    }
  `}

  th {
    position: relative;
  }

  .tooltip-container > #DBTable-tooltip {
    visibility: hidden;
    display: block;
    width: 400px;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 10;
    top: 80%;
    left: 50%;
    margin-left: -60px;
    cursor: pointer;
    background: var(--gray-primary);
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary);
  }

  .tooltip-container:hover #DBTable-tooltip {
    visibility: visible;
  }
`

export const StyledTextarea = styled.textarea`
    resize: none;
    padding: 12px 16px;
    border: 1px solid #fff;
    border-radius: 4px;
    max-height: 200px;
    min-width: 235px;
    overflow-y: auto;
    z-index: 5;
    font-size: 16px;
    box-shadow: 0px 0px 20px 0px #00000080;
    font-family: 'Lato';
    font-weight: 400;
    background-color: #0D0D0F;
    color: #fff;
    position: fixed;
`
