import React, { useState, useEffect, useLayoutEffect } from 'react'
import { StyledTextarea } from './styled'
import useClickAway from '../../Hooks/useClickAway'
import api from '../../api'
import { debounce } from 'lodash'

const MIN_WIDTH = 235 // Minimum width for StyledTextarea

function ExpandedTextRecord ({ setExpandTextRecord, metaData, cell, cellRef, handleUpdateTable }) {
  const [textValue, setTextValue] = useState(cell.value || '')
  const [modalPosition, setModalPosition] = useState({ left: 0, top: 0 })
  const [textareaWidth, setTextareaWidth] = useState(`${MIN_WIDTH}px`)
  const [shouldSave, setShouldSave] = useState(false)
  const modalRef = useClickAway(() => setShouldSave(true))

  const initialValue = cell.value || ''

  useEffect(() => {
    if (cellRef && cellRef.current) {
      cellRef.current.focus()
    }
  }, [cellRef])

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus() // Focus the textarea when the component mounts
    }
  }, [modalRef])

  useLayoutEffect(() => {
    const cellRect = cellRef.current?.getBoundingClientRect()
    const { top, left, height, width } = cellRect || {}
    const modalRect = modalRef.current?.getBoundingClientRect()
    const modalHeight = modalRect.height
    if (top + height + modalHeight > window.innerHeight) {
      setModalPosition({ left: left, top: top - modalHeight + 48 })
    } else if (height < 47) {
      setModalPosition({ left: left, top: top + height - 32 })
    } else {
      setModalPosition({ left: left, top: top + height - 48 })
    }
    if (width > modalRect.width) {
      setTextareaWidth(`${Math.max(width, MIN_WIDTH)}px`)
    } else {
      setTextareaWidth(`${MIN_WIDTH}px`)
    }
  }, [cellRef, modalRef])

  useEffect(() => {
    const handleResize = () => {
      const cellRect = cellRef.current?.getBoundingClientRect()
      if (cellRect) {
        setTextareaWidth(`${Math.max(cellRect.width, MIN_WIDTH)}px`)
      } else {
        setTextareaWidth(`${MIN_WIDTH}px`)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    const currentCellRef = cellRef.current
    if (currentCellRef) {
      resizeObserver.observe(currentCellRef)
    }

    return () => {
      if (currentCellRef) {
        resizeObserver.unobserve(currentCellRef)
      }
    }
  }, [cellRef])

  useEffect(() => {
    const saveTextCellRecord = async () => {
      if (setExpandTextRecord && (shouldSave || textValue?.trim() === initialValue?.trim())) {
        if (textValue?.trim() === initialValue?.trim()) {
          setExpandTextRecord(false)
          return
        }

        if (shouldSave && textValue?.trim() !== initialValue?.trim()) {
          const id = cell.row.original.id
          const tableName = metaData.tableName
          const recordUpdatedFields = {
            [cell.column.id]: textValue.trim()
          }

          if (id) {
            const result = await api.updateRecord(recordUpdatedFields, tableName, id)
            if (result?.error) {
              console.error(result.error)
            } else {
              handleUpdateTable(cell.column.id, textValue.trim())
            }
          }

          setExpandTextRecord(false)
        }
      }
    }

    const debouncedSaveTextCellRecord = debounce(saveTextCellRecord)

    if (shouldSave) {
      debouncedSaveTextCellRecord()
    }

    return () => {
      debouncedSaveTextCellRecord.cancel()
    }
  }, [shouldSave, textValue, initialValue, cell, metaData, handleUpdateTable, setExpandTextRecord])

  useEffect(() => {
    const textarea = modalRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`
      textarea.style.overflowY = textarea.scrollHeight > 200 ? 'scroll' : 'hidden'
    }
  }, [textValue, modalRef])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      setShouldSave(true)
    } else if (event.key === 'Escape') {
      setExpandTextRecord(false)
    }
  }

  const handleChange = (e) => {
    setTextValue(e.target.value)
    setShouldSave(false)
  }

  return (
    <StyledTextarea
      ref={modalRef}
      value={textValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      style={{ width: textareaWidth, left: modalPosition.left, top: modalPosition.top }}
    />
  )
}

export default ExpandedTextRecord
