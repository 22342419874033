export const DashboardCardsName = {
  ACADEMIC_INTEGRITY: 'Academic integrity',
  ADD_STUDENTS: 'Add students',
  ADMINISTRATIVE_DROP_STUDENTS: 'Administrative drop students',
  COHORTS: 'Cohorts',
  COHORT_TRANSFER: 'Cohort transfer',
  CONCEPT_TAGS: 'Concept tags',
  CONTENT_GATING: 'Content gating',
  CSV_DOWNLOAD: 'CSV download',
  EXAM_RETAKES: 'Exam retakes',
  GRADE_REPORT: 'Grade Report',
  PERMISSIONS: 'Permissions',
  PROGRESS_CLONING: 'Progress cloning',
  PROCTORIO_GRADEBOOK: 'Proctorio gradebook',
  RELATIONSHIPS: 'Relationships',
  PROGRESS_RESET: 'Progress reset',
  VIP_USERS: 'VIP users',
  VOUCHER: 'Voucher',
  VOUCHER_CODES: 'Voucher codes',
  WRITING_GRADE_CENTER: 'Writing grade center',
  YELLOWDIG_URLS: 'Yellowdig URLs',
  AMAZON_REFUNDS: 'Amazon refunds',
  AMAZON_STUDENT_PROGRESS: 'Amazon Student Progress',
  GGU_ADMISSION_DECISION_IMPORT: 'GGU Admission Decision Import',
  ASSESSMENT_EXTENSIONS: 'Assessment Extensions',
  GUILD: 'Guild',
  ENROLLMENT_MANAGEMENT: 'Enrollment management',
  DATABASE_TABLES: 'Database tables',
  GGU_COURSE_APPROVAL: 'GGU course approval',
  EXAM_KEY_OVERRIDE: 'Exam Key Override',
  MERGE_ACCOUNTS: 'Merge accounts',
  ENROLL_STUDENTS: 'Enroll students',
  STUDENT_RECORDS: 'Student Records',
  GGU_APPLICATION_MANAGEMENT: 'GGU application management',
  GRADING_SYSTEM_CHECK: 'Grading System Check'
}
