import React, { useState } from 'react'
import { useDebouncedEffect } from '../../../Hooks/useDebounce'
import ModalComponent from '../../../Components/ModalComponent/ModalComponent'
import {
  ClearIcon,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModaLWrapper,
  SearchInput,
  SearchInputWrapper
} from './styles'
import { ReactComponent as SearchIcon } from '../../../assets/icons/icon-magnify.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg'
import api from '../../../api'
import SearchResult from './SearchResult'

function SearchModal ({ setSearchModalOpen }) {
  const [searchValue, setSearchValue] = useState('')
  const [studentData, setStudentData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useDebouncedEffect(
    () => {
      if (searchValue === '') return
      const fetchStudentData = async () => {
        const studentData = await api.getStudentDataByEmailOrId(searchValue)

        setStudentData(studentData)
        setIsLoading(false)
      }

      fetchStudentData()
    },
    [searchValue],
    1000
  )

  const handleSearchValueChange = (event) => {
    if (!isLoading) setIsLoading(true)
    event.stopPropagation()
    setSearchValue(event.target.value)
  }

  return (
    <ModaLWrapper>
      <ModalComponent
        height={300}
        handleClose={() => setSearchModalOpen(false)}
        show
        isResponsive
      >
        <ModalHeader>
          <ModalTitle>Search</ModalTitle>
          <ModalCloseButton onClick={() => setSearchModalOpen(false)}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalHeader>
        <>
          <SearchInputWrapper>
            <SearchInput
              placeholder='Enter email address'
              value={searchValue}
              onChange={(event) => handleSearchValueChange(event)}
            />
            {searchValue ? (
              <ClearIcon onClick={() => setSearchValue('')} />
            ) : (
              <SearchIcon />
            )}
          </SearchInputWrapper>
          <ModalContent>
            <SearchResult
              searchValue={searchValue}
              studentData={studentData}
              isLoading={isLoading}
            />
          </ModalContent>
        </>
      </ModalComponent>
    </ModaLWrapper>
  )
}

export default SearchModal
