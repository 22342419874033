import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContainer,
  CardDescription,
  CardIcon,
  CardTitle,
  Container,
  Header,
  Separator
} from './styles'
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap'
import chevronRight from '../../assets/icons/chevron-right.svg'
import { hasAccessToLink } from '../../utilities/userUtils'
import { getDashboardCardsByUserRole } from '../../utilities/DashboardCards'
import { useAuth0 } from '../Auth0Provider/Auth0Provider'

function DashBoardTools () {
  const { user } = useAuth0()
  const { roles = [], permissions = [] } = user || {}
  const [tools, setTools] = useState(getDashboardCardsByUserRole(roles))

  useEffect(() => {
    if (!roles?.length || getDashboardCardsByUserRole(roles)?.length) return
    const cardsList = getDashboardCardsByUserRole(roles)
    const getCardContent = async () => {
      const menuItems = await Promise.all(
        cardsList.map(async card => {
          const { loadContentFunction, getCount } = card || {}
          if (!loadContentFunction) return card

          const refundRequests = await loadContentFunction()
          const count = getCount(refundRequests)
          return { ...card, count, content: refundRequests }
        })
      )
      setTools(menuItems)
    }

    getCardContent()
    // eslint-disable-next-line
  }, [roles])

  return (
    <Container>
      <Header>Tools</Header>
      <Separator />
      <div className='d-flex flex-wrap'>
        {tools.map((card, index) => {
          const {
            to, name, icon, description, count, content, loadContentFunction
          } = card || {}
          const showSpinner = loadContentFunction && typeof count === 'undefined'
          return hasAccessToLink(card, permissions, roles) && (
            <CardContainer key={index} className='col-12 col-sm-6 col-md-6 col-lg-4'>
              <NavLink
                tag={Link}
                to={{
                  pathname: to,
                  ...(loadContentFunction ? { content } : {})
                }}
                style={{ padding: 0 }}
              >
                <Card>
                  <CardIcon src={icon} alt={name} />
                  <CardTitle>
                    {name}{`${showSpinner ? ' ' : ''}`}
                    <span>
                      {showSpinner
                        ? <i className='fa fa-spinner fa-spin' />
                        : count > 0 ? ' (' + count + ')' : ''}
                    </span>
                    <img src={chevronRight} alt='chevron-right' />
                  </CardTitle>
                  <CardDescription>{description}</CardDescription>
                </Card>
              </NavLink>
            </CardContainer>
          )
        })}
      </div>
    </Container>
  )
}

export default DashBoardTools
