import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { ReactComponent as ClearIconSVG } from '../../../assets/icons/clear-icon.svg'
import { buttonEventsBehavior } from '../../../styles/buttons'

export const ModaLWrapper = styled.div`
  & .modal-wrapper {
    width: 450px;
    padding: 24px;

    ${mediaqueries.smallMobile`
        width: 330px;
    `}
  }
`
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  & > h2 {
    margin: 0;
  }
`
export const ModalTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
`
export const ModalCloseButton = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  ${buttonEventsBehavior}

  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  min-height: 24px;
  & > p {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
  }
`

export const ResultItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  & > p {
    ${mediaqueries.foldable} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
`
export const SearchInputWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  align-self: flex-end;
  border-radius: 5px;
  border: 1px solid var(--border-secondary, #5b6067);
  width: 100%;
  height: 48px;
  background: var(--black-tertiary, #0d0d0f);
`
export const SearchInput = styled.input`
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: inherit;
  border: none;

  &::placeholder {
    color: #5b6067;
  }
  &:focus {
    outline: none;
  }
`

export const ClearIcon = styled(ClearIconSVG)`
  cursor: pointer;

  path {
    fill: var(--text-dark-off, #98a4ae);
  }

  &:hover {
    path {
      fill: #fff;
    }
  }

  &:active {
    opacity: 0.3;
  }
`
export const OpenStudentOverview = styled.a`
  display: flex;
  gap: 6px;
  padding: 8px;
  border: 0;
  background: var(--brand-dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  border-radius: 5px;
  text-decoration: none;

  ${buttonEventsBehavior}
  &:hover {
    color: var(--brand-regular-hover);
    background: #214b52;
    text-decoration: none;
  }

  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
