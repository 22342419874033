import styled from 'styled-components'
import checkMark from '../../../assets/icons/checkmark-white.svg'

export const CustomCheckBox = styled.input`
  // hide default checkbox
  position: relative;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;

  // create custom checkbox
  ::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #5FC4B8;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 0;
  }
  // when checked, show checkmark
  :checked::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 6px;
    left: 2px;
    width: 12px;
    height: 8px;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    transform: rotate(-45deg);
  }

  // when checked, change background color
  :checked::after {
    background-color: #5FC4B8;
  }
`
export const Label = styled.label`
  display: flex;
  align-items: flex-start;

  ${props => props.disabled && `
    opacity: 0.5;
  `}

  input[type='checkbox'] {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
  }
`
export const CustomCheckBoxWithHover = styled(CustomCheckBox)`

  height: 34px;
  width: 34px;

  :checked::before {
    content: url(${checkMark});
    transform: rotate(0);
    display: flex;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 34px;
    height: 34px;
    border-left: 0px solid black;
    border-bottom: 0px solid black;
    justify-content: center;
    align-items: center;
    transform: scale(1.625)
  }

  ::after {
    width: 34px;
    height: 34px; 
    top: 0px;
    left: 0px;
    border-color: ${'transparent'};
    background-color: ${'transparent'};
  }

  :checked::after {
    background-color: ${props =>
    (props.cellIsHovered && !props.disabled) ? '#3D4047' : '#25272B'};
  }

  ${props => (props.ancestorIsHovered) && `
    ::after {
      border-color: ${props.checked ? 'transparent' : '#5B6067'};
    }
  `}

  ${props => (props.cellIsHovered && !props.disabled) && `
    ::after {
      border-color: ${props.checked ? 'transparent' : '#5FC4B8'};
    }
  `}
`

export const LabelWithHover = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  ${props => props.disabled && `
    opacity: 0.3;
  `}

  input[type=checkbox].custom-checkbox-with-hover.boolean-checkbox{
    margin-left: 0px;
    transform: scale(100%);
  }
`
