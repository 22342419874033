import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import DBTable from '../Table/DBTable'
import ServerSidePagination from '../Table/ServerSidePagination'
import {
  ConfirmationMessage,
  ContextMenu,
  TableContainer,
  WarningIcon
} from './styles'
import useContextMenu from '../../Hooks/useContextMenu'
import ModalComponent from '../ModalComponent/ModalComponent'
import { PrimaryButton, SecondaryButton } from '../global.styled'
import Toast from '../ToastComponent/Toast'
import RecordModal from './RecordModal'
import {
  getHeadersFromDataRows,
  getRowsFromDataTable, manageLocalStorage
} from '../../utilities/dataBaseUtils'
import { getColumnMetaData, getFiltersWithRules } from './utils'

function TableViewComponent ({
  tableName,
  refreshTable,
  metaData,
  totalRows,
  setTotalRows,
  view,
  setView,
  sortBy,
  setSortBy,
  pageSize,
  tableHeaders,
  canUserEditDatabase
}) {
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [rightClickedRow, setRightClickedRow] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isDeleteRowLoading, setIsDeleteRowLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [rowToEdit, setRowToEdit] = useState({})
  const [selectedPage, handlePage] = useState(1)

  const { setClicked, setPoints, clicked, points } = useContextMenu()
  const toast = useRef()
  const timerRef = useRef(null)
  const { viewName } = useParams()

  const pageCount = Math.ceil(totalRows / pageSize)

  const { hiddenFields, filters, label } = view || {}

  const metaDataHeaders = useMemo(
    () =>
      metaData.map((header) => ({
        label: header.columnName,
        value: header.columnName
      })),
    [metaData]
  )

  const getDataTable = async (offset) => {
    if (!tableName) return
    if (viewName !== label) return
    if (metaDataHeaders.length === 0) return
    timerRef.current = Date.now()
    const triggerTime = timerRef.current
    setIsTableLoading(true)
    const filterWithRules = getFiltersWithRules(filters, metaDataHeaders)
    const { count, rows } = await api.getTableFilter({
      tableName,
      filters: filterWithRules,
      offset,
      sort: sortBy[0],
      limit: pageSize
    })

    if (timerRef.current === triggerTime) {
      const data = getRowsFromDataTable(rows)
      setData(data)
      setTotalRows(count)
    }
    setIsTableLoading(false)
  }

  useEffect(() => {
    if (!tableName) return
    getDataTable(0)
    // eslint-disable-next-line
  }, [tableName, pageSize, sortBy, refreshTable, filters, label, metaDataHeaders])

  useEffect(() => {
    if (!data?.length) return
    const headers = getHeadersFromDataRows(data)
    setView((old) => ({
      ...old,
      columnOrder: old.columnOrder?.length ? old.columnOrder : headers
    }))
    // eslint-disable-next-line
  }, [data])

  const pageChangeHandler = (selectedPage) => {
    const offset = pageSize * selectedPage
    getDataTable(offset)
  }

  const columns = (tableHeaders?.length ? tableHeaders : getHeadersFromDataRows(data))
    .filter(
      (field) =>
        !hiddenFields?.length || !hiddenFields.includes(field.id.toLowerCase())
    )
    .map((item, index) => {
      return {
        prop: item.id,
        Header: item.id,
        accessor: item.id,
        metaData: getColumnMetaData(item.id, metaData),
        sortType: 'alphanumeric',
        ...(index === 0 && { width: 250 }),
        ...(view.columnWidths?.[item.id] && {
          width: view.columnWidths[item.id]
        }),
        updateTable: (rowIndex, columnId, newValues) => {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: newValues
                }
              }
              return row
            })
          )
        }
      }
    })

  const onRightClickHandler = (e, row) => {
    setClicked(true)
    setPoints({
      x: e.pageX,
      y: e.pageY
    })
    setRightClickedRow([row])
  }

  const onMultiSelectHandler = (selectedRows) => {
    if (selectedRows.length === 10) {
      const { current: { display } = {} } = toast || {}
      display(
        <><WarningIcon /> Warning</>,
        'You can only select up to 10 records at a time'
      )
    }
    setSelectedRows(selectedRows)
  }

  const deleteRecords = async () => {
    setIsDeleteRowLoading(true)
    const rowsToDelete = rightClickedRow.length ? rightClickedRow : selectedRows

    if (!rowsToDelete.length) return
    const rowIds = rowsToDelete.map(({ original: { id } }) => id) || []

    const result = await api.deleteRecords(tableName, rowIds)

    setIsDeleteRowLoading(false)
    setShowConfirmationModal(false)
    manageLocalStorage('deletedRows', rowIds)
    if (result?.error) {
      const { current: { display } = {} } = toast || {}
      display(
        <><WarningIcon /> Error</>,
        result.error
      )
      return
    }
    getDataTable(0)
  }

  const showConfirmationModalHandler = (multi) => {
    if (multi) setRightClickedRow([])
    setShowConfirmationModal(true)
  }

  const onEditClickHandler = (row) => {
    if (!row) return
    setRowToEdit(row)
    setShowEditModal(true)
  }

  const handleCloseModal = () => {
    setShowEditModal(false)
    setRowToEdit({})
  }

  const handleUpdateTable = () => {
    pageChangeHandler(selectedPage - 1)
  }

  // Define the onCellValueChange function
  const onCellValueChange = async (cell, newValue) => {
    const rowId = cell.row.original.id
    const columnId = cell.column.id

    // Update the data with the new value
    const updatedData = data.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          [columnId]: newValue
        }
      }
      return row
    })
    setData(updatedData)

    // Persist the changes using the API
    await api.updateRecord(updatedData.find(row => row.id === rowId), tableName, rowId)
  }

  return (
    <>
      <Toast toast={toast} />
      {showEditModal && (
        <RecordModal
          tableName={tableName}
          metaData={metaData}
          rowToEdit={rowToEdit}
          show={showEditModal}
          handleClose={handleCloseModal}
          handleUpdateTable={handleUpdateTable}
          action='edit'
          canUserEditDatabase={canUserEditDatabase}
          view={view}
        />
      )}
      <TableContainer>
        { !tableName ? null
          : isTableLoading ? <LoadingSpinner /> : (
            (!data?.length)
              ? <p>Oops! No data available for the table at this time.</p>
              : <>
                <DBTable
                  data-testid='table-view-component'
                  columns={columns}
                  data={data}
                  currentView={view}
                  updateCurrentView={(key, value) =>
                    setView((currentView) => ({ ...currentView, [key]: value }))
                  }
                  tableName={tableName}
                  handleSort={setSortBy}
                  sort={sortBy}
                  onRightClickHandler={onRightClickHandler}
                  onMultiSelectHandler={onMultiSelectHandler}
                  onEditClickHandler={onEditClickHandler}
                  canUserEditDatabase={canUserEditDatabase}
                  handleUpdateTable={handleUpdateTable}
                  onCellValueChange={onCellValueChange} // Pass the callback function
                />
                {clicked && (
                  <ContextMenu
                    data-testid='context-menu'
                    top={points.y} left={points.x}>
                    <ul>
                      <li data-testid='delete-context-menu' onClick={() => showConfirmationModalHandler(false)}>
                      Delete this record
                      </li>
                      { selectedRows.length >= 1 && (
                        <li onClick={() => showConfirmationModalHandler(true)}>
                        Delete all selected records
                        </li>
                      )}
                    </ul>
                  </ContextMenu>
                )}
              </>
          )}
      </TableContainer>
      <ServerSidePagination
        pageChangeHandler={pageChangeHandler}
        totalRows={totalRows}
        pageCount={pageCount}
        pageSize={pageSize}
        selectedPage={selectedPage}
        handlePage={handlePage}
      />
      {showConfirmationModal && (
        <ModalComponent
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          title='Delete Confirmation'
        >
          <ConfirmationMessage data-testid='confirmation-message'>
            <p>Are you sure you want to delete {
              rightClickedRow.length
                ? 'this record'
                : `${selectedRows.length} selected records`}?
            </p>
            <p>This action cannot be undone.</p>
            <div className='d-flex justify-content-end'>
              <PrimaryButton
                data-testid='cancel-button'
                className='btn btn-primary'
                onClick={() => setShowConfirmationModal(false)}
              >
            Cancel
              </PrimaryButton>
              <SecondaryButton
                data-testid='delete-button'
                className='btn btn-danger ml-2'
                onClick={deleteRecords}
              >
                {isDeleteRowLoading ? <i className='fa fa-spinner fa-spin' /> : 'Delete'}
              </SecondaryButton>
            </div>
          </ConfirmationMessage>
        </ModalComponent>
      )}
    </>
  )
}

export default TableViewComponent
