import React, { useState } from 'react'
import {
  HeaderSection,
  PageWrapper,
  SearchModalButton,
  Separator
} from './styles'
import { PageTitle } from '../../styles/text'
import AcademicIntegrityTables from './AcademicIntegrityTables'
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg'
import SearchModal from './SearchModal'

function AcademicIntegrity () {
  const [searchModalOpen, setSearchModalOpen] = useState(false)

  return (
    <PageWrapper>
      <HeaderSection>
        <PageTitle>Academic integrity</PageTitle>
        <SearchModalButton onClick={() => setSearchModalOpen(true)}>
          <SearchIcon />
          Search
        </SearchModalButton>
      </HeaderSection>
      <Separator space='24px' />
      <AcademicIntegrityTables />
      {searchModalOpen && (
        <SearchModal setSearchModalOpen={setSearchModalOpen} />
      )}
    </PageWrapper>
  )
}

export default AcademicIntegrity
