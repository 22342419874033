import styled from 'styled-components'

export const Container = styled.div`
  margin: 64px;
`

export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  color: var(--text-primary);
  margin-bottom: 0;
`
