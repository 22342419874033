import React, { useEffect } from 'react'
import {
  ModalCloseButton,
  ModalContainer, ModalHeaderContainer,
  ModalOverlay,
  ModalTitle,
  ModalWrapper
} from './styles/modalComponent'
import { CloseIcon } from '../Guild/style'
import {
  AddButton,
  ButtonsContainer,
  CancelButton
} from '../DatabaseViewer/styles'

function ModalComponent (props) {
  const {
    show,
    handleClose,
    children,
    position,
    title,
    width,
    isResponsive,
    isEditable,
    handleUpdate,
    isSaving
  } = props

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeClick, false)
    return () => {
      document.removeEventListener('keydown', handleEscapeClick, false)
    }
    // eslint-disable-next-line
  }, [])

  const handleEscapeClick = (event) => {
    if (event.keyCode === 27) handleClose()
  }

  return (
    <ModalContainer show={show} position={position} width={width}>
      <ModalOverlay onClick={handleClose} />
      <ModalWrapper
        width={width}
        className='modal-wrapper'
        isResponsive={isResponsive}
      >
        {title && (
          <ModalHeaderContainer>
            {isEditable ? (
              <>
                <ModalTitle>{title}</ModalTitle>
                <ButtonsContainer>
                  <CancelButton
                    tabIndex={0}
                    role='button'
                    data-testid='cancel-button'
                    onClick={handleClose}
                    disabled={isSaving}
                  >
                    cancel
                  </CancelButton>
                  {
                    <AddButton
                      tabIndex={0}
                      role='button'
                      data-testid='update-record-button'
                      onClick={handleUpdate}
                      disabled={isSaving}
                    >
                      {'update'}
                    </AddButton>
                  }
                </ButtonsContainer>
              </>
            ) : (
              <>
                <ModalTitle>{title}</ModalTitle>
                <ModalCloseButton
                  tabIndex={0}
                  role='button'
                  onClick={handleClose}
                >
                  <CloseIcon />
                </ModalCloseButton>
              </>
            )}
          </ModalHeaderContainer>
        )}
        {children}
      </ModalWrapper>
    </ModalContainer>
  )
}

export default ModalComponent
