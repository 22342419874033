import React from 'react'
import StudentGradeReportWrapper from '../Components/StudentGradeReport/GradeReportWrapper'
import ResetStudentProgress from '../Components/ResetStudentProgress/ResetStudentProgress'
import GradeDetail from '../Components/GradeDetail/GradeDetail'
import CloneStudentProgress from '../Components/CloneStudentProgress/CloneStudentProgress'
import EditGrades from '../Components/EditGrades/EditGrades'
import CohortTransfer from '../Components/CohortTransfer/CohortTransfer'
import CohortForm from '../Components/Cohorts/CohortForm'
import CreateBulkCohort from '../Components/Cohorts/CreateBulkCohort'
import Cohorts from '../Components/Cohorts/Cohorts'
import CohortDetail from '../Components/Cohorts/CohortDetail'
import VoucherCode from '../Components/VoucherCode/VoucherCode'
import WritingGradeCenter from '../Components/WritingGradeCenter/WritingGradeCenter'
import ExamRetakes from '../Components/ExamRetakes/ExamRetakes'
import ExamGradeBook from '../Components/ExamGradeBook'
import Notifications from '../Components/Notifications'
import NotificationCreate from '../Components/Notifications/Create'
import NotificationReview from '../Components/Notifications/Review'
import NotificationUpdate from '../Components/Notifications/Update'
import CreateCommunity from '../Components/Yellowdig/CreateCommunity'
import PermissionsManagerV2 from '../views/PermissionsManager'
import AddStudent from '../Components/Students/AddStudent'
import ActiveLearningExport from '../Components/ActiveLearningExport/ActiveLearningExport'
import CSVStudentImport from '../Components/Students/CSVStudentImport'
import CSVDownloadV2 from '../Components/CSVDownloadV2'
import ContentGating from '../Components/ContentGating/ContentGating'
import Voucher from '../Components/Voucher'
import AddRelationships from '../Components/Relationships/AddRelationships'
import DashBoardTools from '../Components/DashboardTools/DashBoardTools'
import AmazonRefunds from '../Components/AmazonRefunds'
import DatabaseViewerComponent
from '../Components/DatabaseViewer/DatabaseViewerComponent'
import ManageRelationships from '../Components/Relationships/ManageRelationships'
import EditRelationships from '../Components/Relationships/EditRelationships'
import AddCourseLicenses from '../Components/Relationships/Licenses/AddCourseLicenses'
import AmazonStudentProgress
from '../Components/AmazonStudentProgress/AmazonStudentProgress'
import AdminStudentDrop from '../Components/AdminStudentDrop/AdminStudentDrop'
import Guild from '../Components/Guild'
import AssessmentExtensions
from '../Components/AssessmentExtensions/AssessmentExtensions'
import AddExtension
from '../Components/AssessmentExtensions/AddExtension/AddExtension'
import StudentSubmissionWrapper from '../Components/StudentSubmission/StudentSubmissionWrapper'
import StudentRecords from '../Components/StudentRecords/StudentRecords'
import StudentRecordDetails
from '../Components/StudentRecords/StudentRecordDetails/StudentRecordDetails'
import EnrollmentManagement from '../views/EnrollmentManagement'
import EnrollStudentsTool
from '../Components/EnrollStudentsTool/EnrollStudentsTool'
import EnrollStudent
from '../Components/EnrollStudentsTool/EnrollStudent/EnrollStudent'
import MergeAccounts from '../Components/MergeAccounts/MergeAccounts'
import GradingCheck from '../Components/GradingCheck/GradingCheck'
import CourseDetails from '../Components/GradingCheck/CourseDetails'
import ExamKeyOverride from '../views/ExamKeyOverride'
import GGURegistration from '../views/GGURegistration'
import GGUAppManagement from '../views/GGUAppManagement'
import ProspectPage from '../views/GGUAppManagement/ProspectPage/ProspectPage'
import ViewLicenseDashboard
from '../Components/Relationships/ViewLicense/ViewLicenseDashboard'
import VipUserTool from '../Components/VipUser/VipUserTool'
import VoidLicenses from '../Components/Relationships/VoidLicenses/VoidLicenses'
import AcademicIntegrity from '../views/AcademicIntegrity'
import StudentOverview from '../views/AcademicIntegrity/StudentOverview'
import ConceptTags from '../Components/ConceptTags/ConceptTags'

const routes = [
  {
    path: '/dashboard',
    main: DashBoardTools
  },
  {
    path: '/student-grade-report',
    requiredPermissions: ['gradeReport.view'],
    main: StudentGradeReportWrapper
  },
  {
    path: '/student-progress-reset',
    requiredPermissions: ['progressReset.view'],
    main: ResetStudentProgress
  },
  {
    path: '/students/:step',
    requiredPermissions: ['addStudents.view'],
    main: AddStudent
  },
  {
    path: '/students-csv-import',
    requiredPermissions: ['addStudents.view'],
    main: CSVStudentImport
  },
  {
    path: '/student-grade-detail/:courseId/:courseName/:studentEmail/:cohortName',
    requiredPermissions: ['gradeReport.view'],
    main: GradeDetail
  },
  {
    path: '/clone-student-progress',
    requiredPermissions: ['progressCloning.view'],
    main: CloneStudentProgress
  },
  {
    path: '/csv-download/:pageType',
    requiredPermissions: ['csvDownload.view'],
    main: CSVDownloadV2
  },
  {
    path: '/student-edit-grades/:studentEmail/:studentName/:courseUuid/:cohortName',
    requiredPermissions: ['gradeReport.view'],
    main: EditGrades
  },
  {
    path: '/cohort-transfer',
    requiredPermissions: ['cohortTransfer.view'],
    main: CohortTransfer
  },
  {
    path: '/concept-tags',
    main: ConceptTags
  },
  {
    path: '/vip-user',
    requiredPermissions: ['vipUsers.view'],
    main: VipUserTool
  },
  {
    path: '/cohorts/bulk-create',
    requiredPermissions: ['cohorts.view'],
    main: CreateBulkCohort
  },
  {
    path: '/cohorts/create',
    requiredPermissions: ['cohorts.view'],
    main: CohortForm
  },
  {
    path: '/cohorts/:cohortId/edit',
    requiredPermissions: ['cohorts.view'],
    main: CohortForm
  },
  {
    path: '/cohorts',
    requiredPermissions: ['cohorts.view'],
    main: Cohorts
  },
  {
    path: '/cohorts/:cohortId',
    requiredPermissions: ['cohorts.view'],
    main: CohortDetail
  },
  {
    path: '/voucher-code',
    requiredPermissions: ['voucherCodes.view'],
    main: VoucherCode
  },
  {
    path: '/exam-gradebook',
    requiredPermissions: ['proctorioGradebook.view'],
    main: ExamGradeBook
  },
  {
    path: '/yellowdig-create-community',
    requiredPermissions: ['yellowdigURLs.view'],
    main: CreateCommunity
  },
  {
    path: '/notifications',
    main: Notifications
  },
  {
    path: '/notifications/create',
    main: NotificationCreate
  },
  {
    path: '/notifications/review',
    main: NotificationReview
  },
  {
    path: '/notifications/update',
    main: NotificationUpdate
  },
  {
    path: '/writing-grade-center',
    requiredPermissions: ['writingGradeCenter.view'],
    main: WritingGradeCenter
  },
  {
    path: '/student-submission/:courseId/:cohortName/:assignmentUUID/:studentEmail',
    requiredPermissions: ['writingGradeCenter.view'],
    main: StudentSubmissionWrapper
  },
  {
    path: '/permissions-manager',
    requiredPermissions: ['permissionManager.view'],
    main: PermissionsManagerV2
  },
  {
    path: '/active-learning-export',
    main: ActiveLearningExport
  },
  {
    path: '/exam-retakes',
    requiredPermissions: ['examRetakes.view'],
    main: ExamRetakes
  },
  {
    path: '/content-gating',
    requiredPermissions: ['contentGating.view'],
    main: ContentGating
  },
  {
    path: '/voucher/:studentId/:voucherId',
    requiredPermissions: ['vouchers.view'],
    id: 'voucher',
    main: Voucher
  },
  {
    path: '/voucher/:studentId',
    requiredPermissions: ['vouchers.view'],
    id: 'voucher',
    main: Voucher
  },
  {
    path: '/voucher',
    requiredPermissions: ['vouchers.view'],
    id: 'voucher',
    main: Voucher
  },
  {
    path: '/relationships/new',
    requiredPermissions: ['relationships.view'],
    main: AddRelationships
  },
  {
    path: '/relationships/edit',
    requiredPermissions: ['relationships.view'],
    main: EditRelationships
  },
  {
    path: '/relationships/add-course-licenses',
    requiredPermissions: ['relationships.view'],
    main: AddCourseLicenses
  },
  {
    path: '/relationships/view-license-dashboard',
    requiredPermissions: ['relationships.view'],
    main: ViewLicenseDashboard
  },
  {
    path: '/relationships/void-licenses',
    requiredPermissions: ['relationships.view'],
    main: VoidLicenses
  },
  {
    path: '/exam-key-override',
    requiredPermissions: ['examKeyOverride.view'],
    main: ExamKeyOverride
  },
  {
    path: '/ggu-registration',
    requiredPermissions: ['gguCourseApproval.view'],
    main: GGURegistration
  },
  {
    path: '/ggu-app-management',
    requiredPermissions: ['gguAppManagement.view'],
    main: GGUAppManagement
  },
  {
    path: '/ggu-app-management/prospect/:prospectId',
    requiredPermissions: ['gguAppManagement.view'],
    main: ProspectPage
  },
  {
    path: '/relationships',
    requiredPermissions: ['relationships.view'],
    main: ManageRelationships
  },
  {
    path: '/amazon-refunds',
    requiredPermissions: ['amazonRefund.view'],
    main: AmazonRefunds
  },
  {
    path: '/amazon-student-progress/:step',
    requiredPermissions: ['amazonStudentProgress.view'],
    main: AmazonStudentProgress
  },
  {
    path: '/database-viewer',
    requiredPermissions: ['dbViewer.view'],
    main: DatabaseViewerComponent
  },
  {
    path: '/database-viewer/:tableName',
    main: DatabaseViewerComponent
  },
  {
    path: '/database-viewer/:tableName/:viewName',
    main: DatabaseViewerComponent
  },
  {
    path: '/admin-student-drop',
    requiredPermissions: ['adminDropStudents.view'],
    main: AdminStudentDrop
  },
  {
    path: '/enrollment-management',
    requiredPermissions: ['enrollmentManagement.view'],
    main: EnrollmentManagement
  },
  {
    path: '/enroll-students',
    requiredPermissions: ['enrollStudents.view'],
    main: EnrollStudentsTool
  },
  {
    path: '/enroll-students/enroll',
    main: EnrollStudent
  },
  {
    path: '/ggu-admission-decision-import',
    requiredPermissions: ['gguAdmissionImport.view'],
    main: () => <CSVStudentImport isGGUAddmissionDecision />
  },
  {
    path: '/guild',
    requiredPermissions: ['guild.view'],
    main: Guild
  },
  {
    path: '/assessment-extensions',
    requiredPermissions: ['assessmentExtensions.view'],
    main: AssessmentExtensions
  },
  {
    path: '/assessment-extensions/create',
    requiredPermissions: ['assessmentExtensions.view'],
    main: AddExtension
  },
  {
    path: '/student-records',
    requiredPermissions: ['studentRecords.view'],
    main: StudentRecords
  },
  {
    path: '/student-record-details/:studentId/:studentEmail',
    main: StudentRecordDetails
  },
  {
    path: '/merge-accounts',
    requiredPermissions: ['mergeAccounts.view'],
    main: MergeAccounts
  },
  {
    path: '/grading-check',
    main: GradingCheck
  },
  {
    path: '/grading-check/course-details',
    main: CourseDetails
  },
  {
    path: '/academic-integrity',
    main: AcademicIntegrity
  },
  {
    path: '/academic-integrity/student/:studentId',
    main: StudentOverview
  },
  {
    path: '/*',
    main: DashBoardTools
  }
]

export default routes
