// FILE: src/Components/CheckBox/CheckBoxWithHover.js

import React, { useState, useEffect } from 'react'
import { LabelWithHover, CustomCheckBoxWithHover } from './styled'

// Create a new component that wraps CheckBox and adds ancestorHovered functionality
const CheckBoxWithHover = ({
  id,
  label,
  name,
  value: initialValue,
  onChange,
  checked: initialChecked,
  disabled,
  ancestorIsHovered,
  cellIsHovered,
  isCellClicked
}) => {
  const [checked, setChecked] = useState(initialChecked)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (initialChecked !== undefined && initialChecked !== checked) {
      setChecked(initialChecked)
    }
  }, [initialChecked, checked])

  useEffect(() => {
    if (isCellClicked !== undefined && isCellClicked !== checked) {
      if (!disabled) {
        setChecked(isCellClicked)
        setValue(isCellClicked)
        onChange(isCellClicked)
      }
    }
  }, [isCellClicked, disabled, checked, onChange])

  const handleChange = (event) => {
    const newChecked = event.target.checked
    setChecked(newChecked)
    onChange(newChecked)
  }

  return (
    <LabelWithHover disabled={disabled}>
      <CustomCheckBoxWithHover
        className='custom-checkbox-with-hover boolean-checkbox'
        type='checkbox'
        name={name}
        value={value}
        id={id}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        ancestorIsHovered={ancestorIsHovered}
        cellIsHovered={cellIsHovered}
      />
      {label && <span>{label}</span>}
    </LabelWithHover>
  )
}

// Define default props
CheckBoxWithHover.defaultProps = {
  onChange: () => {},
  checked: false
}

CheckBoxWithHover.displayName = 'CheckBoxWithHover'
export default CheckBoxWithHover
