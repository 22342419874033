import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import _ from 'lodash'
import {
  Cell,
  CellItem,
  CellList,
  ExpandLinkedCell,
  ExpandLinkedCellWrapper,
  CellSpacer,
  CellText,
  CellTextContainer
} from './styled'
import ExpandedRecords from './ExpandedRecords'
import ExpandedTextRecord from './ExpandedTextRecord'
import CheckBoxWithHover from '../CheckBox/CheckBoxWithHover'

function DBTableCell (props) {
  const {
    cell,
    index,
    handleClick,
    handleUpdateTable,
    isHovered,
    onCellValueChange, // Add a prop to handle cell value changes
    canUserEditDatabase
  } = props
  const { getCellProps, value: initialValue, column: { id, Header, metaData } } = cell

  const [expandLinkedRecords, setExpandLinkedRecords] = useState(false)
  const [isCellClicked, setIsCellClicked] = useState(undefined)
  const [cellIsHovered, setCellIsHovered] = useState(false)
  const [cellValue, setCellValue] = useState(initialValue) // Add state for cell value
  const cellRef = useRef()
  const [activeCell, setActiveCell] = useState(null)
  const isEmptyArrayCell = Array.isArray(cellValue) && cellValue.length === 0
  const isTextField = metaData?.dataType === 'text'
  const isEmptyTextField = isTextField && !cellValue
  const [expandTextRecord, setExpandTextRecord] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeCell && !cellRef.current?.contains(event.target)) {
        activeCell.classList.remove('empty-class-click', 'active-empty-cell')
        setActiveCell(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [activeCell])

  const displayExpandButton = (isCellEmpty = false) => (
    <ExpandLinkedCellWrapper
      data-testid='expand-linked-records'
      onClick={() => isTextField ? setExpandTextRecord(true) : setExpandLinkedRecords(true)}
      className={(isCellEmpty && activeCell === cellRef.current) ? 'active-empty-cell' : 'expand-linked-cell-wrapper'}
    >
      <ExpandLinkedCell className={(isCellEmpty || isEmptyTextField) && 'expand-empty-linked-cell'} />
    </ExpandLinkedCellWrapper>
  )

  const handleEmptyLinkedRecordsCellClick = (e, value) => {
    if (activeCell) {
      activeCell.classList.remove('empty-class-click')
    }
    e.target.classList.add('empty-class-click')
    setActiveCell(e.target)
  }

  const handleCellClick = () => {
    setIsCellClicked(!isCellClicked)
  }

  const handleMouseEnter = () => {
    setCellIsHovered(true)
  }

  const handleMouseLeave = () => {
    setCellIsHovered(false)
  }

  const handleCheckboxChange = (newChecked) => {
    setCellValue(newChecked)
    if (onCellValueChange) {
      onCellValueChange(cell, newChecked) // Propagate changes to parent
    }
  }

  const getCellValue = (value) => {
    const isObjectArray = Array.isArray(value) && value.some((x) => x?.value)
    if (isObjectArray) {
      const { joinedTo } = metaData || {}
      return (
        <>
          <CellList className='linked-records'>
            {value.map((data, index) => {
              if (!data) return null
              return (
                <CellItem
                  key={data?.id + index}
                  onClick={() => handleClick(data, joinedTo)}
                >
                  {data?.value}
                </CellItem>
              )
            })}
          </CellList>
          <CellSpacer />
          {displayExpandButton()}
        </>
      )
    }
    if (isEmptyArrayCell) {
      const isCellEmpty = true
      return (
        <>
          <CellSpacer />
          {displayExpandButton(isCellEmpty)}
        </>
      )
    }
    const isArray = Array.isArray(value) && value.length > 0
    if (isArray) {
      return value
        .filter(Boolean)
        .map(getCellValue)
        .join(', ')
    }
    const isBoolean = typeof value === 'boolean'
    if (isBoolean) {
      return value.toString()
    }

    const isObject = _.isObject(value)
    if (isObject) return JSON.stringify(value)
    const dateFormats = ['YYYY-MM-DD']
    const isDate = moment(value, dateFormats, true).isValid()
    if (isDate) {
      return moment(value).format('MM/DD/YYYY')
    }
    const dateTimeFormats = ['YYYY-MM-DDTHH:mm:ss.SSSZ']
    const isDateTime = moment(value, dateTimeFormats, true).isValid()
    if (isDateTime) {
      return moment(value).format('MM/DD/YYYY hh:mm A')
    }

    if (isTextField) {
      return (
        <>
          <CellTextContainer className={!isEmptyTextField && 'linked-records'}>
            <CellText>{value}</CellText>
            <CellSpacer />
            {displayExpandButton(!value)}
          </CellTextContainer>
        </>
      )
    }

    return value
  }

  const isRowSelection = id === 'selection'
  const isPersonalInfo = [
    'email', 'name', 'id', 'preferredName'
  ].includes(Header)

  const handleHoverEffect = isEmptyArrayCell || isEmptyTextField ? 'empty-cell' : 'non-empty-cell'

  const showCheckBox = metaData?.dataType === 'boolean'
  if (metaData?.dataType === 'boolean' && getCellValue(cellValue) === null) {
    setCellValue(false)
  }

  return (
    <td
      key={index}
      tabIndex='0'
      {...getCellProps()}
      className={classNames(
        'bodyCell',
        { 'fs-exclude': isPersonalInfo },
        { 'edit-cell': index === 1 },
        { 'align-empty-cell': isEmptyArrayCell || isEmptyTextField },
        { 'center-content': showCheckBox },
        handleHoverEffect
      )}
      onClick={(e) =>
        isEmptyArrayCell || isEmptyTextField ? handleEmptyLinkedRecordsCellClick(e, cellValue)
          : handleCellClick(e)
      }
      onDoubleClick={(e) =>
        isEmptyArrayCell ? setExpandLinkedRecords(true) : isTextField ? setExpandTextRecord(true) : null
      }
      ref={cellRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {expandLinkedRecords && canUserEditDatabase && (
        <ExpandedRecords
          setExpandLinkedRecords={setExpandLinkedRecords}
          metaData={metaData}
          cell={cell}
          cellRef={cellRef}
          handleClick={handleClick}
        />
      )}
      {expandTextRecord && canUserEditDatabase && (
        <ExpandedTextRecord
          setExpandTextRecord={setExpandTextRecord}
          metaData={metaData}
          cell={cell}
          cellRef={cellRef}
          handleUpdateTable={handleUpdateTable}
        />
      )}
      {isRowSelection && (
        <span {...cell.getCellProps()}>{cell.render('Cell')}</span>
      )}
      {
        showCheckBox && (
          <CheckBoxWithHover
            id={index}
            label={''}
            name={'Checkbox with header ' + Header}
            value={cellValue}
            onChange={handleCheckboxChange} // Handle checkbox change
            checked={cellValue}
            disabled={!canUserEditDatabase}
            ancestorIsHovered={isHovered}
            cellIsHovered={cellIsHovered}
            isCellClicked={isCellClicked}
          />
        )
      }
      {!showCheckBox && (
        <Cell
          ref={cellRef}
          className={(isEmptyArrayCell && !activeCell) ? 'empty-cell' : ''}
        >
          {getCellValue(cellValue)}
        </Cell>
      )}
    </td>
  )
}

export default DBTableCell
