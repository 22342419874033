import React from 'react'
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner'
import { OpenStudentOverview, ResultItem } from './styles'
import { ReactComponent as ExternalLink } from '../../../assets/icons/box-arrow-up-right.svg'

function SearchResult ({ searchValue, studentData, isLoading }) {
  if (isLoading) return <LoadingSpinner />

  if (!searchValue) {
    return (
      <p>
        Looking for a student? Enter the student’s email in the field above to
        visit their academic integrity overview page.
      </p>
    )
  }

  if (!isLoading && Object.keys(studentData).length === 0) {
    return <p>No results found</p>
  }

  return (
    <ResultItem data-testid='search-result'>
      <p>{studentData?.email}</p>
      <OpenStudentOverview
        href={`#/academic-integrity/student/${studentData?.id}`}
        target='_blank'
        data-testid='open-student-overview'
      >
        <ExternalLink />
        View
      </OpenStudentOverview>
    </ResultItem>
  )
}

export default SearchResult
