import people from '../assets/icons/people.svg'
import book from '../assets/icons/book.svg'
import arrowLeftRight from '../assets/icons/arrow-left-right.svg'
import lock from '../assets/icons/lock.svg'
import unlock from '../assets/icons/unlock.svg'
import fileArrowDown from '../assets/icons/file-arrow-down.svg'
import pencilSquare from '../assets/icons/pencil-square.svg'
import barChart from '../assets/icons/bar-chart.svg'
import fingerPrint from '../assets/icons/fingerprint.svg'
import files from '../assets/icons/files.svg'
import journalCheck from '../assets/icons/journal-check.svg'
import handshake from '../assets/icons/handshake-regular.svg'
import eraser from '../assets/icons/eraser.svg'
import personBadge from '../assets/icons/person-badge.svg'
import invoice from '../assets/icons/invoice.svg'
import upcScan from '../assets/icons/upc-scan.svg'
import pen from '../assets/icons/pen.svg'
import chatLeftText from '../assets/icons/chat-left-text.svg'
import amazonRefunds from '../assets/icons/amazon-refunds.svg'
import door from '../assets/icons/door.svg'
import api from '../api'
import { getOutstandingRequests } from './amazonRefunds'
import fileBarGraph from '../assets/icons/file-bar-graph.svg'
import ggu from '../assets/icons/ggu.svg'
import guild from '../assets/icons/guild.svg'
import journalRichtext from '../assets/icons/journal-richtext.svg'
import calendarPlus from '../assets/icons/calendar-plus.svg'
import personSearch from '../assets/icons/person-search.svg'
import personAdd from '../assets/icons/person-add.svg'
import registerIcon from '../assets/icons/register.svg'
import mergeAccounts from '../assets/icons/merge-accounts.svg'
import personLines from '../assets/icons/person-lines-fill.svg'
import tableIcon from '../assets/icons/table.svg'
import flagIcon from '../assets/icons/flag.svg'
import cardChecklist from '../assets/icons/card-checklist.svg'
import { hasAcademicIntegrityFlag, isConceptTagEnabled } from '../config'
import { DashboardCardsName } from '../Constants/dashboardCardsName'
import { isCAAOTeamMember, isPartnershipsManager } from './permissionsManagerUtil'
import tagIcon from '../assets/icons/tag.svg'

export const cards = [
  ...(hasAcademicIntegrityFlag
    ? [
      {
        to: '/academic-integrity',
        icon: flagIcon,
        name: DashboardCardsName.ACADEMIC_INTEGRITY,
        description: 'Approve and review academic integrity incidents'
      }
    ]
    : []),
  {
    to: '/students/student-information',
    alternateRoute: '/students/attempts-and-purchases',
    requiredPermissions: ['addStudents.view'],
    icon: people,
    name: DashboardCardsName.ADD_STUDENTS,
    description: 'Enroll students into courses or set up a test account'
  },
  {
    to: '/admin-student-drop',
    requiredPermissions: ['adminDropStudents.view'],
    icon: door,
    name: DashboardCardsName.ADMINISTRATIVE_DROP_STUDENTS,
    description: 'Review and drop students who are elibigle for DropNR and Admin Drop'
  },
  {
    to: '/cohorts',
    requiredPermissions: ['cohorts.view'],
    icon: book,
    name: DashboardCardsName.COHORTS,
    description: 'Review and manage everything about cohorts'
  },
  {
    to: '/cohort-transfer',
    requiredPermissions: ['cohortTransfer.view'],
    icon: arrowLeftRight,
    name: DashboardCardsName.COHORT_TRANSFER,
    description: 'Move a student into a new course or cohort'
  },
  {
    to: '/concept-tags',
    name: DashboardCardsName.CONCEPT_TAGS,
    icon: tagIcon,
    description: 'Check where concept tags appear in a course and how they are performing'
  },
  {
    to: '/content-gating',
    requiredPermissions: ['contentGating.view'],
    icon: lock,
    name: DashboardCardsName.CONTENT_GATING,
    description: 'Require students or entire cohorts to engage with material'
  },
  {
    to: '/csv-download/student-criteria',
    requiredPermissions: ['csvDownload.view'],
    icon: fileArrowDown,
    name: DashboardCardsName.CSV_DOWNLOAD,
    description: 'Select and download student progress data'
  },
  {
    to: '/exam-retakes',
    requiredPermissions: ['examRetakes.view'],
    icon: pencilSquare,
    name: DashboardCardsName.EXAM_RETAKES,
    description: 'Grant students the permission to retake an exam'
  },
  {
    to: '/student-grade-report',
    requiredPermissions: ['gradeReport.view'],
    icon: barChart,
    name: DashboardCardsName.GRADE_REPORT,
    description: 'View student progress and grades or make adjustments'
  },
  {
    to: '/permissions-manager',
    requiredPermissions: ['permissionManager.view'],
    icon: fingerPrint,
    name: DashboardCardsName.PERMISSIONS,
    description: 'Grant, edit, or remove access to admin tools'
  },
  {
    to: '/clone-student-progress',
    requiredPermissions: ['progressReset.view'],
    icon: files,
    name: DashboardCardsName.PROGRESS_CLONING,
    description: 'Copy a student\'s progress to another account'
  },
  {
    to: '/exam-gradebook',
    requiredPermissions: ['proctorioGradebook.view'],
    icon: journalCheck,
    name: DashboardCardsName.PROCTORIO_GRADEBOOK,
    description: 'Review exam recordings for academic integrity violations'
  },
  {
    to: '/relationships',
    requiredPermissions: ['relationships.view'],
    icon: handshake,
    name: DashboardCardsName.RELATIONSHIPS,
    description: 'Add and manage partner relations'
  },
  {
    to: '/student-progress-reset',
    requiredPermissions: ['progressReset.view'],
    icon: eraser,
    name: DashboardCardsName.PROGRESS_RESET,
    description: 'Reset all progress that a student has made in any course'
  },
  {
    to: '/vip-user',
    requiredPermissions: ['vipUsers.view'],
    icon: personBadge,
    name: DashboardCardsName.VIP_USERS,
    description: 'Add or edit VIP access to courses for exisiting and new users'
  },
  {
    to: '/voucher',
    requiredPermissions: ['vouchers.view'],
    icon: invoice,
    name: DashboardCardsName.VOUCHER,
    description: 'Review Amazon vouchers for enrolled students'
  },
  {
    to: '/voucher-code',
    requiredPermissions: ['voucherCodes.view'],
    icon: upcScan,
    name: DashboardCardsName.VOUCHER_CODES,
    description: 'Create transfer voucher codes for the marketing site'
  },
  {
    to: '/writing-grade-center',
    requiredPermissions: ['writingGradeCenter.view'],
    icon: pen,
    name: DashboardCardsName.WRITING_GRADE_CENTER,
    description: 'Read and grade the assignments students have submitted'
  },
  {
    to: '/yellowdig-create-community',
    requiredPermissions: ['yellowdigURLs.view'],
    icon: chatLeftText,
    name: DashboardCardsName.YELLOWDIG_URLS,
    description: 'Launch and manage all discussion board communities'
  },
  {
    to: '/amazon-refunds',
    requiredPermissions: ['amazonRefunds.view'],
    icon: amazonRefunds,
    loadContentFunction: api.getAmazonRefundRequests,
    getCount: refundRequests => {
      const requests = getOutstandingRequests(refundRequests)
      return requests?.length || 0
    },
    name: DashboardCardsName.AMAZON_REFUNDS,
    description: 'Approve or deny new requests or view the history of all requests'
  },
  {
    to: '/amazon-student-progress/upload-csv',
    requiredPermissions: ['amazonStudentProgress.view'],
    icon: fileBarGraph,
    name: DashboardCardsName.AMAZON_STUDENT_PROGRESS,
    description: 'Download a completed CSV to share on the Career Choice portal'
  },
  {
    to: '/ggu-admission-decision-import',
    requiredPermissions: ['gguAdmissionImport.view'],
    icon: ggu,
    name: DashboardCardsName.GGU_ADMISSION_DECISION_IMPORT,
    description: 'Bulk import admitted GGU students'
  },
  {
    to: '/assessment-extensions',
    requiredPermissions: ['assessmentExtensions.view'],
    icon: calendarPlus,
    name: DashboardCardsName.ASSESSMENT_EXTENSIONS,
    description: 'View all assessment extensions and create new ones'
  },
  {
    to: '/guild',
    requiredPermissions: ['guild.view'],
    icon: guild,
    name: DashboardCardsName.GUILD,
    description: 'Upload the ELT and view past uploads'
  },
  {
    to: '/enrollment-management',
    requiredPermissions: ['enrollmentManagement.view'],
    icon: journalRichtext,
    name: DashboardCardsName.ENROLLMENT_MANAGEMENT,
    description: 'Select a group of students and update their enrollment status'
  },
  {
    to: '/database-viewer',
    requiredPermissions: ['dbViewer.view'],
    icon: tableIcon,
    name: DashboardCardsName.DATABASE_TABLES,
    description: 'View, create, or edit records that manage Outlier dashboards and course sites'
  },
  {
    to: '/ggu-registration',
    requiredPermissions: ['gguCourseApproval.view'],
    icon: registerIcon,
    name: DashboardCardsName.GGU_COURSE_APPROVAL,
    description: 'Review students’ course selections for the upcoming semester'
  },
  {
    to: '/exam-key-override',
    icon: unlock,
    requiredPermissions: ['examKeyOverride.view'],
    name: DashboardCardsName.EXAM_KEY_OVERRIDE,
    description: 'Unlock an exam for all students in a relationship'
  },
  {
    to: '/merge-accounts',
    requiredPermissions: ['mergeAccounts.view'],
    icon: mergeAccounts,
    name: DashboardCardsName.MERGE_ACCOUNTS,
    description: 'Merge multiple student accounts into one'
  },
  {
    to: '/enroll-students',
    requiredPermissions: ['enrollStudents.view'],
    icon: personAdd,
    name: DashboardCardsName.ENROLL_STUDENTS,
    description: 'Enroll a student, bulk enroll via CSV, or set up a new test account'
  },
  {
    to: '/student-records',
    requiredPermissions: ['studentRecords.view'],
    icon: personSearch,
    name: DashboardCardsName.STUDENT_RECORDS,
    description: 'Lookup student records and view their Outlier journey'
  },
  {
    to: '/ggu-app-management',
    requiredPermissions: ['gguAppManagement.view'],
    icon: personLines,
    name: DashboardCardsName.GGU_APPLICATION_MANAGEMENT,
    description: 'Review or update GGU applications'
  },
  {
    to: '/grading-check',
    name: DashboardCardsName.GRADING_SYSTEM_CHECK,
    icon: cardChecklist,
    description: 'Compare course grading systems across Outlier databases'
  }
]

export function getPartnerShipManagerCards () {
  return cards
    .filter(card => [DashboardCardsName.VIP_USERS, DashboardCardsName.RELATIONSHIPS]
      .includes(card.name)).sort((a, b) => a.name.localeCompare(b.name))
}

export function getCAAOTeamMemberCards () {
  return cards
    .filter(card => [DashboardCardsName.ADD_STUDENTS, DashboardCardsName.CONCEPT_TAGS, DashboardCardsName.CSV_DOWNLOAD,
      DashboardCardsName.DATABASE_TABLES, DashboardCardsName.GRADE_REPORT, DashboardCardsName.GRADING_SYSTEM_CHECK,
      DashboardCardsName.PROGRESS_RESET, DashboardCardsName.VIP_USERS, DashboardCardsName.WRITING_GRADE_CENTER,
      DashboardCardsName.YELLOWDIG_URLS
    ].includes(card.name)).sort((a, b) => a.name.localeCompare(b.name))
}

export function getCardsBasedOnFeatureFlag (cards) {
  if (isConceptTagEnabled()) {
    return cards
  } else {
    return cards.filter(card => card.name !== DashboardCardsName.CONCEPT_TAGS).sort((a, b) => a.name.localeCompare(b.name))
  }
}

export function getDashboardCardsByUserRole (roles = []) {
  if (isPartnershipsManager(roles)) {
    return getPartnerShipManagerCards()
  } else if (isCAAOTeamMember(roles)) {
    return getCardsBasedOnFeatureFlag(getCAAOTeamMemberCards())
  } else {
    return getCardsBasedOnFeatureFlag(cards)
  }
}
