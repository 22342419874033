import React, { useEffect, useState, useRef } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { FormContainer, FormLabel, InputContainer, ModalContents, WarningMessage } from './styles'
import RecordInput from './RecordInput'
import api from '../../api'

const ExpandedItem = (props) => {
  const { isLoading, tableName, selectedItem, handleClose, canUserEditDatabase } = props
  const { rowDetails, columnMetaData } = selectedItem || {}
  const { rows } = rowDetails || {}
  const { id, fields } = rows?.[0] || {}
  const [row, setRow] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const originalRow = useRef({})
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    originalRow.current = fields
    setRow(fields)
    setErrorMessage('')
  }, [fields])

  const handleUpdate = async () => {
    setIsSaving(true)
    const {
      applicationInfo,
      cohortUpdate,
      createdAt,
      createdOn,
      dateDOBChanged,
      updatedAt,
      lastUpdatedAt,
      lastRefundDate,
      latestRefundDate,
      cohortCount,
      paidAmountPerCourse,
      ...rest
    } = row

    const recordUpdatedFields = id
      ? Object.keys(rest).reduce((acc, key) => {
        if (originalRow.current[key] !== rest[key]) {
          acc[key] = rest[key]
        }
        return acc
      }, {})
      : rest

    if (Object.keys(recordUpdatedFields).length === 0) {
      handleClose()
      return
    }

    if (id) {
      const result = await api.updateRecord(recordUpdatedFields, tableName, id)
      if (result?.error) {
        setErrorMessage(result.error)
        setIsSaving(false)
      }
    }
  }

  return (
    <ModalComponent
      title={tableName}
      show
      width='572px'
      handleClose={handleClose}
      isEditable={canUserEditDatabase}
      handleUpdate={handleUpdate}
      isSaving={isSaving}
    >
      <ModalContents>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          columnMetaData?.map((field) => {
            const { columnName, isAutoGenerated } = field
            if (columnName === 'id' || isAutoGenerated) return null
            return (
              <FormContainer key={columnName}>
                <FormLabel>{columnName}</FormLabel>
                <InputContainer >
                  <RecordInput
                    field={field}
                    row={row}
                    canUserEditDatabase={canUserEditDatabase}
                    setRow={setRow}
                  />
                </InputContainer>
              </FormContainer>
            )
          })
        }
      </ModalContents>
      { errorMessage && <WarningMessage>{errorMessage}</WarningMessage>}
    </ModalComponent>
  )
}

export default ExpandedItem
